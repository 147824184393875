import { Pagination, Empty } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo, useEffect } from 'react';
import { api } from '../../utils/api';
import styles from './index.less';
import moment from 'moment';
import { nanoid } from 'nanoid';
import NotificationCenterStore from './store';

const NotificationCenter = observer(() => {
  const store = useMemo(() => {
    return new NotificationCenterStore();
  }, []);

  const { list, messageType, data, page, pageSize, total, queryMessageTypes, onChangeActiveTab, onPaginationChange } = store;

  const gotoDetail = (type: number, orderNo: string, orderId: number, title?: string): void => {
    if (messageType === 4) {
      top.egenie.openTab(`/egenie-ts-pms/collaborativeReturnOrder?orderNo=${orderNo}`, '60003', '协同退货单');
      return;
    }

    if (messageType === 6) {
      top.egenie.openTab(`/egenie-ts-oms/historyOrder?sale_order_no-14-10=${orderNo}`, 502, '订单查询');
      return;
    }

    if (messageType === 7) {
      top.egenie.openTab(`/egenie-ts-oms/aftersale?after_sale_no-14-1=${orderNo}`, 502, '订单查询');
    }

    if (messageType === 8) {
      if (title === '发货已超时') {
        const endTime = moment().subtract(48, 'hours')
          .format('YYYY-MM-DD HH:mm:ss');
        window.top.egenie.openTab(`/egenie-ts-oms/order?courier_print_mark_state-4-14=1&date=sale_order_status.pay_time,,${endTime}`, 505, '订单处理', 'icon_ddcl');
      } else if (title === '发货即将超时') {
        const endTime = moment().subtract(48, 'hours')
          .format('YYYY-MM-DD HH:mm:ss');
        const startTime = moment().subtract(42, 'hours')
          .format('YYYY-MM-DD HH:mm:ss');

        window.top.egenie.openTab(`/egenie-ts-oms/order?courier_print_mark_state-4-14=1&date=sale_order_status.pay_time,${startTime},${endTime}`, 505, '订单处理', 'icon_ddcl');
      }
      return;
    }
    if (type === 2) {
      const currentWareHouse = sessionStorage.getItem('currentWareHouse');
      top.egenie.openTab(`/egenie-vendor-pos/selectOrder/index?shopAndWarehouseId=${currentWareHouse}&isShowDetail=1&mallSaleOrderId=${orderId}`, '310', '选款订单');
    } else {
      top.egenie.openTab(`/egenie-vendor-pos/yilianCollaborativeOrder?posCooperateSaleOrderNo=${orderNo}`, '4001', '协同订单');
    }
  };

  useEffect(() => {
    queryMessageTypes();
  }, []);

  return (
    <div className={styles.notificationCenter}>
      {list.length > 0 && (
        <div className={styles.left}>
          <div
            className={styles.tabs}
          >
            {list.map((item) => (
              <div
                className={messageType === item.type ? `${styles.tabItem} ${styles.tabItemActive}` : styles.tabItem}
                key={item.type}
                onClick={() => onChangeActiveTab(item.type)}
              >
                {item.title}
                {item.number > 0 && (
                  <span className={styles.unreadNum}>
                    {item.number > 99 ? '99+' : item.number}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.right}>
        <div className={styles.messageList}>
          {Array.isArray(data) && data.length > 0 ? data.map((item) => {
            return (
              <div
                className={(messageType === 3 || messageType === 4) ? styles.messageItem : `${styles.messageItem} ${styles.nohover}`}
                key={nanoid()}
                onClick={(messageType > 2) ? () => gotoDetail(item.type, item.orderNo, item.orderId, item.title) : null}
              >
                <div className={styles.header}>
                  <span>
                    {messageType === 8 ? '依链代发超时警告' : item.title}
                  </span>
                  <span className={styles.time}>
                    {messageType !== 8 && item.orderTime }
                    {(messageType > 2) && (
                      <span className={styles.lookDetail}>
                        查看详情
                        <span className={`${styles.arrowIcon} icon-home_xq`}/>
                      </span>
                    )}
                  </span>
                </div>
                <div className={styles.content}>
                  {item.columns.map((col) => {
                    return (
                      <div
                        className={styles.column}
                        key={col.label}
                      >
                        <div className={styles.columnTitle}>
                          {col.label}
                        </div>
                        <div className={styles.columnContent}>
                          {col.value}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }) : (
            <div className={styles.empty}>
              <Empty
                description="暂无数据哦！"
                image={`${api.oss}/images/empty.png`}
              />
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Pagination
            current={page}
            onChange={onPaginationChange}
            pageSize={pageSize}
            showQuickJumper
            showSizeChanger
            showTotal={(totalCount: number) => `共${totalCount}条记录`}
            total={total}
          />
        </div>
      </div>
    </div>
  );
});

export default NotificationCenter;
