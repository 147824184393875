import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action, flow } from 'mobx';
import { api } from '../../utils';
import type { Message, MessageType, LabelAndValue, ListItem } from './interface';

export default class NotificationCenterStore {
  @observable public messageType = 1; // 1.收款通知 2.退款通知 3.销售订单 4.退货订单

  @observable public page = 1;

  @observable public pageSize = 20;

  @observable public total = 0;

  @observable public list: MessageType[] = []; // 分类列表

  @observable public data: ListItem[] = [];

  @observable public readAll = false; // 是否已发送全部已读请求

  @action public onChangeActiveTab = (activeKey: number): void => {
    this.messageType = activeKey;
    this.page = 1;
    this.list.forEach((item) => {
      if (item.type === activeKey) {
        item.number = 0;
      }
    });
    this.queryMessageList();
  };

  @action public queryMessageTypes = async(): Promise<void> => {
    const res: BaseData<MessageType[]> = await request({ url: api.getMessageTypes });
    if (res.data && res.data.length > 0) {
      this.messageType = res.data[0].type;
      res.data[0].number = 0;
    }
    this.list = res.data;
    this.queryMessageList();
  };

  @action public queryMessageList = flow(function* () {
    const res: PaginationData<Message> = yield request({
      url: api.queryMessageList,
      method: 'POST',
      data: {
        messageType: this.messageType,
        page: this.page,
        pageSize: this.pageSize,
      },
    });
    this.total = res.data.totalCount;
    this.data = res.data.list.map((item) => {
      const { title, orderTime, orderId, orderNo, type } = item;
      return {
        orderNo,
        orderId,
        title,
        orderTime,
        type,
        columns: this.convertColumns(item),
      };
    });
    const idx = this.list.findIndex((item) => item.number > 0);
    if (!this.readAll && idx > -1) {
      this.readAllMessage();
    }
  });

  @action public onPaginationChange = (page: number, pageSize: number): void => {
    this.page = page;
    this.pageSize = pageSize;
    this.queryMessageList();
  };

  @action public readAllMessage = async(): Promise<void> => {
    await request({ url: api.readAllMessage });
    this.readAll = true;
  };

  public convertColumns = (msg: Message): LabelAndValue[] => {
    const { typeDesc, amount, orderNo, customerName, remark, price, title } = msg;
    if (this.messageType === 1 || this.messageType === 2) {
      const name = this.messageType === 1 ? '收款' : '退款';
      const columns = [
        {
          label: `${name}类型`,
          value: typeDesc,
        },
        {
          label: `${name}金额(元)`,
          value: price,
        },
        {
          label: `${name}单号`,
          value: orderNo,
        },
        {
          label: '客户名称',
          value: customerName,
        },
        {
          label: this.messageType === 1 ? '备注' : '退款原因',
          value: remark,
        },
      ];
      return this.messageType === 1 ? columns : [
        ...columns.slice(0, 2),
        {
          label: '退货数量(件)',
          value: amount || 0,
        },
        ...columns.slice(2),
      ];
    } else if (this.messageType === 8) {
      return [
        {
          label: `${title}订单数(笔）`,
          value: amount,
        },
        {
          label: ' ',
          value: '',
        },
        {
          label: ' ',
          value: '',
        },
        {
          label: ' ',
          value: '',
        },
        {
          label: ' ',
          value: '',
        },
      ];
    } else if (this.messageType === 7) {
      return [
        {
          label: '订单类型',
          value: typeDesc,
        },
        {
          label: '金额(元)',
          value: price,
        },
        {
          label: '数量(件)',
          value: amount,
        },
        {
          label: '售后单号',
          value: orderNo,
        },
        {
          label: '客户名称',
          value: customerName,
        },
        {
          label: '备注',
          value: remark,
        },
      ];
    } else {
      return [
        {
          label: '订单类型',
          value: typeDesc,
        },
        {
          label: '金额(元)',
          value: price,
        },
        {
          label: '数量(件)',
          value: amount,
        },
        {
          label: '订单号',
          value: orderNo,
        },
        {
          label: '客户名称',
          value: customerName,
        },
        {
          label: '备注',
          value: remark,
        },
      ];
    }
  };
}
